/*
*查询账户余额和交易流水  http://219.128.52.2:18765/doc.html#/haolv-consumer/t-us-company-account-controller/getAccountFlowListUsingPOST
*/
const __request = require(`./__request/__request_contentType_json`)
const request = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/wallet/getAccountFlowList',
        data: data
    }
    return __request(pParameter)
}
export default request