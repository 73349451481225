import moment from "moment";

// 余额账户
import consumer_wallet_getAccountFlowList from "@/lib/data-service/haolv-default/consumer_wallet_getAccountFlowList.js";
// 创建充值订单
import wallet_createRechargeOrder from "@/lib/data-service/haolv-default/wallet_createRechargeOrder.js";
// 修改支付密码
import consumer_web_company_updateCompany from "@/lib/data-service/haolv-default/consumer_web_company_updateCompany";
export default {
  name: "Balance",
  data() {
    const validatorAmount = (rule, value, callback) => {
      if (value) {
        if (/\D/g.test(value)) {
          callback(new Error('请输入正确的数值'));
        } else if (value > this.usableAmount && this.dialogTile === '提款') {
          callback(new Error('余额不足无法提现'));
        } else {
          callback();
        }
      } else {
        callback(new Error('请输入提款金额'));
      }
    };

    return {
      loading: false,
      list: [],
      usableAmount: 0,
      form: {
        currentPage: 1,
        pageSize: 10,
        transactionStartTime: "",
        closingTime: "",
        flowType: "",
      },
      paymentDate: null,
      flowTypeList: [
        { label: "全部", value: "" },
        { label: "充值", value: 1 },
        { label: "购物", value: 2 },
        { label: "退款", value: 3 },
      ],
      pager: {
        pageSizes: [10, 15, 20, 25, 30],
        total: 0,
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },

      dialogVisible: false,
      dialogTile: "",
      amountForm: {
        dealAmount: "",
      },
      amountRules: {
        dealAmount: [
          // { required: true, message: "请输入金额", trigger: "blur" },
          // { pattern: /\d/, message: "请输入正确的数值", trigger: "blur" },
          { required: true, validator: validatorAmount, trigger: "blur" },
        ],
      },

      showChangePassword: false,

      passwordForm: {
        wornPassword: "",
        password: "",
        checkPassword: "",
      },
      passwordFormRules: {
        wornPassword: [
          {
            required: true,
            message: "请输入原密码",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            validator: (rule, value, callback) => {
              const re = /[\w`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]+/;
              if (!value) {
                callback(new Error("请输入新密码"));
              } else if (re.test(value) === false) {
                callback(new Error("只能输入数字、字母、特殊字符"));
              } else if (value.length < 6 || value.length > 20) {
                callback(new Error("密码长度要在6到20个字符之间"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        checkPassword: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请再次输入新密码"));
              } else if (value !== this.passwordForm.password) {
                callback(new Error("两次输入密码不一致"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    req_list() {
      if (this.loading) return;
      this.loading = true;
      consumer_wallet_getAccountFlowList(this.form)
        .then((res) => {
          const detail = res.datas;
          this.usableAmount = detail.usableAmount ? detail.usableAmount : 0;
          const list = detail.flowListResults.list;
          this.pager.total = detail.flowListResults.totalCount;

          list.forEach((item) => {
            item.gmtCreate = moment(item.gmtCreate).format(
              "YYYY-MM-DD HH:mm:ss"
            );
            switch (item.flowType) {
              case 1:
                item.flowTypeText = "充值";
                break;
              case 2:
                item.flowTypeText = "购物";
                break;
              case 3:
                item.flowTypeText = "退款";
                break;
            }
          });

          this.list = list;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    change_date(val) {
      const form = this.form;
      if (val) {
        form.transactionStartTime = val[0];
        form.closingTime = val[1];
      } else {
        form.transactionStartTime = "";
        form.closingTime = "";
      }
    },
    search() {
      this.form.currentPage = 1;
      this.req_list();
    },
    handleSizeChange(pageSize) {
      this.form.pageSize = pageSize;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.form.currentPage = currentPage;
      this.req_list();
    },

    close_amount() {
      this.dialogVisible = false;
      this.dialogTile = "";
      this.amountForm.dealAmount = "";
      this.$refs.amountForm.clearValidate();
    },

    popup_amount(type) {
      if (type === 1) {
        this.dialogTile = "充值";
      } else if (type === 2) {
        this.dialogTile = "提款";
      }
      this.dialogVisible = true;
    },

    input_amount(val) {
      const form = this.amountForm;
      const index = val.indexOf(".");
      // form.dealAmount = val.replace(/![0-9]/g, '');
      if (index !== -1) {
        form.dealAmount = val.substring(0, index + 3);
      }
    },

    commit_amount() {
      this.$refs.amountForm.validate((valid) => {
        if (valid) {
          const dialogTile = this.dialogTile;
          const dealAmount = this.amountForm.dealAmount;
          if (dialogTile === "充值") {
            this.loading = true;
            wallet_createRechargeOrder({ dealAmount }).then((res) => {
              this.loading = false;
              this.close_amount();
              this.$router
                .push({
                  name: "admin-finance-balance-recharge",
                  query: {
                    id: res.datas,
                  },
                })
                .catch((err) => {
                  this.loading = false;
                });
            });
          } else if (dialogTile === "提款") {
            this.$router.push({
              name: "admin-finance-balance-draw",
              query: {
                dealAmount,
              },
            });
            this.close_amount();
          }
        } else {
          return false;
        }
      });
    },

    // 修改支付密码
    onShowChangePassowrd() {
      this.passwordForm = {
        wornPassword: "",
        password: "",
        checkPassword: "",
      };
      this.showChangePassword = true;
    },
    // 确认修改密码
    onSubmitChangePassword() {
      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          let body = {
            password: this.passwordForm.password,
            wornPassword: this.passwordForm.wornPassword,
          };
          consumer_web_company_updateCompany(body)
            .then(() => {
              this.$message.success("修改成功");
            })
            .finally(() => {
              this.showChangePassword = false;
            });
        } else {
          this.$message.error("请检查是否有字段未填写");
          return false;
        }
        this.showChangePassword = false;
      });
    },
  },
  created() {},
  activated() {
    this.req_list();
  },
  watch: {},
  computed: {},
};
