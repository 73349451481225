/*
*新建充值订单记录  http://219.128.52.2:18765/doc.html#/haolv-consumer/t-us-company-account-controller/createRechargeOrderUsingPOST
*/
const __request = require(`./__request/__request_contentType_json`)
const request = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/wallet/createRechargeOrder',
        data: data
    }
    return __request(pParameter)
}
export default request