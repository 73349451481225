/*
* 修改授信支付密码
* http://219.128.52.2:18765/doc.html#/haolv-biz/t-us-company-controller/updateCompanyUsingPOST
*/
const __request = require(`./__request/__request_contentType_json`)
const request = (data) => {
  if (!data) data = {}

  const password = data.password
  const wornPassword = data.wornPassword

  let pParameter = {
    method: 'post',
    urlSuffix: '/consumer/web/company/updateCompany',
    data: {
      password,
      wornPassword,
    }
  }
  return __request(pParameter)
}
export default request